@import '../../../styles/gifts/sass/basics';

.bb-stepper-with-icons {
  background: $green-600;
  max-width: 1920px;
  margin-left: auto;
  margin-right: auto;
  color: $white;

  &__container {
    @media (min-width: $screen-xlg) {
      margin-left: $grid-gutter-width * -0.5;
      margin-right: $grid-gutter-width * -0.5;
    }
  }

  &__steps {
    display: flex;
    flex-direction: column;
    padding-top: 8px;
    padding-bottom: 8px;
    justify-content: stretch;

    @media (min-width: $screen-md-min) {
      flex-direction: row;
      padding-top: 34px;
      padding-bottom: 34px;
      gap: 30px;
      align-items: center;
    }

    @media (min-width: $screen-lg) {
      gap: 26px;
    }
  }

  &__arrow {
    display: none;

    @media (min-width: $screen-lg) {
      display: block;

      svg {
        display: block;
      }
    }
  }
}

.step {
  display: flex;
  align-items: center;
  position: relative;
  padding: 12px 54px 12px 32px;
  line-height: 19px;
  min-height: 38px;
  box-sizing: content-box;

  &:not(:last-child) {
    border-bottom: 2px solid transparentize($gray-50, 0.9);
  }

  @media (min-width: $screen-md-min) {
    flex: 1 1 0;
    padding: 0 0 0 76px;
    font-size: 18px;
    line-height: 26px;
    min-height: 52px;

    &:not(:last-child) {
      border-bottom-width: 0;
    }
  }

  @media (min-width: $screen-lg) {
    font-size: 20px;
  }

  &__number {
    position: absolute;
    left: 8px;
    font-weight: bold;

    @media (min-width: $screen-md-min) {
      display: none;
    }
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 38px;
    height: 38px;
    position: absolute;
    right: 8px;

    @media (min-width: $screen-md-min) {
      right: auto;
      left: 0;
      width: 60px;
      height: 60px;
    }
  }
}